/* You can add global styles to this file, and also import other style files */
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

* {
  font-family: Poppins;
}

.container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.no-content {
  color: gray;
}

.verified {
  font-size: 0.9rem;
  color: #00bcd4;
  display: inline-block;
}
